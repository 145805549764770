import React from 'react'
// import { graphql, Link, useStaticQuery } from 'gatsby'
// import github from '@/img/github-icon.svg';
// import logo from '@/img/logo.svg';
// import {
//   WpMenu
// } from '@/types';
//
export const Navbar = () => {
  return <div>navbar</div>
}
//   const data = useStaticQuery<{
//     menu: WpMenu
//   }>(graphql`
//       query menu {
//           wpMenu(name: {eq: "header"}) {
//               id
//               name
//               slug
//               menuItems {
//                   nodes {
//                       label
//                       id
//                       path
//                       title
//                       url
//                   }
//               }
//           }
//       }
//   `);
//
//   return (
//     <nav className="navbar is-transparent">
//       <div className="container">
//         <div className="navbar-brand">
//           <Link to="/" className="navbar-item">
//             <figure className="image">
//               <img src={logo} alt="Kaldi" style={{ width: '88px' }} />
//             </figure>
//           </Link>
//         </div>
//         <div className="navbar-start">
//           {data.menu.menuItems.nodes.map(node => (
//             <Link
//               className="navbar-item"
//               to={`/${node.path}/`}
//               key={node.path}
//             >
//               {node.title}
//             </Link>
//           ))}
//         </div>
//         <div className="navbar-end">
//           <a
//             className="navbar-item"
//             href="https://github.com/GatsbyCentral/gatsby-starter-wordpress"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <span className="icon">
//               <img src={github} alt="Github" />
//             </span>
//           </a>
//         </div>
//       </div>
//     </nav>
//   )
// }
//
